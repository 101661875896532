import React, { Component } from "react"
import gql from "graphql-tag"
import { graphql } from "react-apollo"
import { LinkContainer } from "react-router-bootstrap"
import { Link } from "react-router-dom"
import {
  Row,
  Col,
  Table,
  Button,
  ButtonToolbar,
  DropdownButton,
  Dropdown,
} from "react-bootstrap"
import PageHeader from "../../../components/PageHeader"

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: this.props.search,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    this.setState({ search: event.target.value })
  }

  handleSubmit(event) {
    event.preventDefault()
    this.props.onSubmit(this.state.search)
  }

  render() {
    return (
      <form className={this.props.className} onSubmit={this.handleSubmit}>
        <div
          className="input-group input-group-sm"
          style={{ marginLeft: "5px" }}
        >
          <input
            type="text"
            className="form-control"
            onChange={this.handleChange}
            placeholder="Search"
            value={this.state.search}
          />
          <span className="input-group-btn">
            <input className="btn btn-default" type="submit" value="Search" />
          </span>
        </div>
      </form>
    )
  }
}

const PostIndex = props => {
  const { loading, variables, posts, loadMorePosts, filterPosts } = props.data

  return (
    <div>
      <PageHeader>Posts</PageHeader>
      <Row>
        <Col md={12}>
          <ButtonToolbar>
            <LinkContainer to={`/admin/posts/new`}>
              <Button variant="primary" size="small">
                New Post
              </Button>
            </LinkContainer>
            <DropdownButton
              id="filter-posts-by-type"
              variant="light"
              size="small"
              title={
                <span>
                  <strong>Type: </strong>
                  {selectedPostType(variables.type)}
                </span>
              }
            >
              <Dropdown.Item
                onClick={() =>
                  filterPosts({ type: ["NEWS", "PODCAST", "VIDEO"] })
                }
              >
                All
              </Dropdown.Item>
              <Dropdown.Item onClick={() => filterPosts({ type: ["NEWS"] })}>
                News
              </Dropdown.Item>
              <Dropdown.Item onClick={() => filterPosts({ type: ["PODCAST"] })}>
                Podcast
              </Dropdown.Item>
              <Dropdown.Item onClick={() => filterPosts({ type: ["VIDEO"] })}>
                Video
              </Dropdown.Item>
            </DropdownButton>
            <DropdownButton
              id="filter-posts-by-status"
              variant="light"
              size="small"
              title={
                <span>
                  <strong>Status: </strong>
                  {selectedPostStatus(variables.published)}
                </span>
              }
            >
              <Dropdown.Item onClick={() => filterPosts({ published: null })}>
                All
              </Dropdown.Item>
              <Dropdown.Item onClick={() => filterPosts({ published: true })}>
                Published
              </Dropdown.Item>
              <Dropdown.Item onClick={() => filterPosts({ published: false })}>
                Unpublished
              </Dropdown.Item>
            </DropdownButton>
            <DropdownButton
              id="filter-posts-by-featured"
              variant="light"
              size="small"
              title={
                <span>
                  <strong>Rank: </strong>
                  {selectedPostRank(variables.featured)}
                </span>
              }
            >
              <Dropdown.Item onClick={() => filterPosts({ featured: null })}>
                All
              </Dropdown.Item>
              <Dropdown.Item onClick={() => filterPosts({ featured: true })}>
                Featured
              </Dropdown.Item>
              <Dropdown.Item onClick={() => filterPosts({ featured: false })}>
                Normal
              </Dropdown.Item>
            </DropdownButton>
            <Search
              id="post-search"
              className="form-inline"
              search={variables.search || ""}
              onSubmit={search => filterPosts({ search })}
            />
          </ButtonToolbar>
        </Col>
      </Row>

      <hr />

      <Table bordered hover striped size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Type</th>
            <th>Category</th>
            <th>Short URL</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan={5}>Loading...</td>
            </tr>
          )}
          {!loading && posts.edges.length === 0 && (
            <tr>
              <td colSpan={5}>
                <strong>No results found</strong>
              </td>
            </tr>
          )}
          {!loading &&
            posts &&
            posts.edges.map((edge, i) => renderPost(edge, i, props.match))}
        </tbody>
      </Table>
      {posts && posts.pageInfo.hasNextPage && (
        <Button variant="primary mb-5" onClick={() => loadMorePosts()}>
          Load More
        </Button>
      )}
    </div>
  )
}

const renderPost = ({ node }, idx, match) => (
  <tr key={idx}>
    <td>{node.id}</td>
    <td>
      <Link to={`${match.path}/${node.slug}/edit`}>{node.title}</Link>
    </td>
    <td>{postType(node)}</td>
    <td>{renderCategories(node)}</td>
    <td>{node.shortUrl}</td>
    <td>
      <LinkContainer to={`/post/${node.slug}`}>
        <Button variant="outline-dark" size="sm">
          {isPublished(node) ? "View" : "Preview"}
        </Button>
      </LinkContainer>
    </td>
  </tr>
)

const isPublished = ({ publishedAt }) => publishedAt !== null

const postType = ({ type }) => {
  switch (type) {
    case "NEWS":
      return "News"
    case "PODCAST":
      return "Podcast"
    case "VIDEO":
      return "Video"
    default:
      return "???"
  }
}

const selectedPostType = type => {
  if (type) {
    if (type.length > 1) {
      return "All"
    }
    return postType({ type: type[0] })
  } else {
    return "All"
  }
}

const selectedPostStatus = published => {
  if (published === null) {
    return "All"
  }
  if (published) {
    return "Published"
  } else {
    return "Unpublished"
  }
}

const selectedPostRank = featured => {
  if (featured === null) {
    return "All"
  }
  if (featured) {
    return "Featured"
  } else {
    return "Normal"
  }
}

const renderCategories = ({ categories }) =>
  categories.map(({ name }) => name).join(", ")

const PostsQuery = gql`
  query Posts(
    $type: [PostType]
    $cursor: String
    $published: Boolean
    $featured: Boolean
    $search: String
  ) {
    posts: allPosts(
      first: 20
      after: $cursor
      type: $type
      published: $published
      featured: $featured
      search: $search
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          title
          type
          slug
          shortUrl
          publishedAt
          categories {
            name
          }
        }
      }
    }
  }
`

export default graphql(PostsQuery, {
  options: {
    errorPolicy: "all",
  },
  props: props => {
    const { posts, fetchMore, refetch, variables } = props.data
    return {
      data: {
        ...props.data,
        filterPosts: vars => {
          refetch({ ...variables, ...vars })
        },
        loadMorePosts: type => {
          return fetchMore({
            query: PostsQuery,
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEdges = fetchMoreResult.posts.edges
              const pageInfo = fetchMoreResult.posts.pageInfo

              return newEdges.length
                ? {
                    posts: {
                      __typename: previousResult.posts.__typename,
                      edges: [...previousResult.posts.edges, ...newEdges],
                      pageInfo,
                    },
                  }
                : previousResult
            },
            variables: {
              cursor: posts.pageInfo.endCursor,
              type: type ? type : variables.type,
            },
          })
        },
      },
    }
  },
})(PostIndex)
