// @ts-nocheck
import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { Route, Switch, Redirect } from "react-router-dom"

import AdminLayout from "./AdminLayout"
import AppLayout from "./AppLayout"

//import "bootstrap/scss/bootstrap.scss"

import { DFPManager } from "react-dfp"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faEnvelope, faSearch } from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import "../css/index.css"
import Login from "../pages/login"

library.add(faEnvelope, faSearch, fab)

export const POST_TYPES = ["News", "Podcast", "Video"]

export const refreshAds = () =>
  DFPManager.getGoogletag().then(gt => {
    if (typeof gt.pubads === "function") {
      console.log("reloading ads...")
      gt.pubads().refresh()
    }
  })

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const token = localStorage.getItem("token")

      return token ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/admin/login",
            state: { from: props.location },
          }}
        />
      )
    }}
  />
)

export default () => {
  return (
    <Router>
      <Switch>
        <Route path="/admin/login" component={Login} />
        <ProtectedRoute
          exact
          path="/admin"
          component={() => <Redirect to={{ pathname: "/admin/posts" }} />}
        />
        <ProtectedRoute path="/admin" component={AdminLayout} />
        <Route path="/" component={AppLayout} />
      </Switch>
    </Router>
  )
}
