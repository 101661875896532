import React from "react"
import gql from "graphql-tag"
import { Mutation } from "react-apollo"
import { Button } from "react-bootstrap"

const DELETE_POST = gql`
  mutation DeletePost($slug: String!) {
    result: deletePost(slug: $slug) {
      message
      success
    }
  }
`

export default ({ history, slug }) => (
  <Mutation mutation={DELETE_POST}>
    {(deletePost, { data }) => (
      <Button
        variant="danger"
        className="btn-block"
        onClick={() => {
          if (window.confirm("Are you sure you want to delete this post?")) {
            deletePost({
              refetchQueries: ["Posts"],
              variables: { slug: slug },
            }).then(({ data: { result } }) => {
              if (result.success) {
                history.push(`/admin/posts`)
              }
            })
          }
        }}
      >
        Delete
      </Button>
    )}
  </Mutation>
)
