import React from "react"
import gql from "graphql-tag"
import { Mutation } from "react-apollo"
import Select from "react-select"
import { ListGroup, ListGroupItem } from "react-bootstrap"

const CategoryManager = ({ post, categories }) => (
  <Mutation mutation={ADD_POST_TO_CATEGORY}>
    {mutate => (
      <ListGroup style={{ marginBottom: 0 }}>
        <Select
          className="list-group-item"
          placeholder="Please select a category"
          name="categories"
          options={categories.filter(
            ({ label }) => mapCategoryToPostType[label] === post.type,
          )}
          onChange={({ value }) =>
            mutate({
              refetchQueries: ["GetPost", "Posts"],
              variables: {
                input: {
                  categoryId: value,
                  postSlug: post.slug,
                },
              },
            })
          }
        />
        {post.categories.map(category => renderCategory(category, post))}
      </ListGroup>
    )}
  </Mutation>
)

const mapCategoryToPostType = {
  News: "NEWS",
  Podcast: "PODCAST",
  Videos: "VIDEO",
}

const renderCategory = (category, post) => (
  <ListGroupItem key={category.slug}>
    {category.name}
    <Mutation mutation={REMOVE_POST_FROM_CATEGORY}>
      {mutate => (
        <button
          type="button"
          className="close"
          aria-label="Close"
          style={{ cursor: "pointer" }}
          onClick={() =>
            mutate({
              refetchQueries: ["GetPost", "Posts"],
              variables: {
                input: {
                  categoryId: category.id,
                  postSlug: post.slug,
                },
              },
            })
          }
        >
          <span aria-hidden="true">&times;</span>
        </button>
      )}
    </Mutation>
  </ListGroupItem>
)

const ADD_POST_TO_CATEGORY = gql`
  mutation AddPostToCategory($input: AddPostToCategoryInput!) {
    result: addPostToCategory(input: $input) {
      success
    }
  }
`

const REMOVE_POST_FROM_CATEGORY = gql`
  mutation RemovePostFromCategory($input: RemovePostFromCategoryInput!) {
    result: removePostFromCategory(input: $input) {
      success
    }
  }
`

export default CategoryManager
