import React from "react"
import gql from "graphql-tag"
import { Query } from "react-apollo"
import { withRouter } from "react-router-dom"
import PostContent from "../../components/PostContent"
import PublishedAt from "../../components/PublishedAt"
import { Button, ResponsiveEmbed, Image } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
//import Disqus from "react-disqus-comments"
import ShareButtons, {
  MediaOutlet,
  MediaOutletLink,
} from "../../components/Sharing"
import SoundCloud from "../../components/SoundCloud"
import { ReactComponent as Stitcher } from "../../img/stitcher.svg"
import { ReactComponent as ApplePodcasts } from "../../img/apple-podcasts.svg"
import { ReactComponent as Spotify } from "../../img/spotify.svg"
import { ReactComponent as SoundCloudIcon } from "../../img/soundcloud.svg"
import MetaTags from "react-meta-tags"

const isLoggedIn = () => localStorage.getItem("token") !== null

const Show = ({ match }) => {
  const slug = match.params.slug

  return (
    <Query query={GET_POST} variables={{ slug }}>
      {({ loading, data }) => {
        if (loading) {
          return null
        }

        const { post } = data

        if (!post) {
          return <h1>Not found</h1>
        }

        return (
          <div className="post-show">
            <MetaTags>
              <title>{post.title}</title>
              <meta property="og:type" content="website" />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:title" content={post.title} />
              <meta property="og:description" content={post.excerpt} />
              <meta property="og:image" content={post.mediumImage.url} />
            </MetaTags>
            {post.type === "VIDEO" && (
              <ResponsiveEmbed a16by9>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
              </ResponsiveEmbed>
            )}

            {(post.type === "NEWS" || post.type === "PODCAST") && (
              <Image
                src={post.largeImage && post.largeImage.url}
                alt={post.title}
                fluid
              />
            )}

            <header className="bordered">
              <h1 style={{ marginTop: 30 }}>{post.title}</h1>
              <PublishedAt
                style={{ marginBottom: 30 }}
                publishedAt={post.publishedAt}
              />
              <ShareButtons post={post} />
              {post.type === "PODCAST" && (
                <React.Fragment>
                  <MediaOutletLink href="https://itunes.apple.com/us/podcast/soundworks-collection/id685229051">
                    <MediaOutlet>
                      <ApplePodcasts fill="#9933cc" />
                      Apple Podcasts
                    </MediaOutlet>
                  </MediaOutletLink>

                  <MediaOutletLink href="https://soundcloud.com/soundworkscollection">
                    <MediaOutlet>
                      <SoundCloudIcon fill="#FF3300" />
                      SoundClound
                    </MediaOutlet>
                  </MediaOutletLink>

                  <MediaOutletLink href="https://open.spotify.com/show/4Yvv5mWbvWI4MSv4sg88wA?si=nKiq6bvZT6-EwkZXBGRFkA">
                    <MediaOutlet>
                      <Spotify fill="#1ED760" />
                      Spotify
                    </MediaOutlet>
                  </MediaOutletLink>

                  <MediaOutletLink href="https://www.stitcher.com/podcast/michael-coleman/soundworks-collection">
                    <MediaOutlet>
                      <Stitcher fill="#000" />
                      Stitcher
                    </MediaOutlet>
                  </MediaOutletLink>
                </React.Fragment>
              )}
              {isLoggedIn() && (
                <div>
                  <LinkContainer to={`/admin/posts/${post.slug}/edit`}>
                    <Button variant="default">Edit</Button>
                  </LinkContainer>
                </div>
              )}
            </header>

            <section id="post-excerpt">
              <p>{post.excerpt}</p>
            </section>

            {post.type === "PODCAST" && post.soundCloudUrl && (
              <div className="mt-3">
                <SoundCloud id={post.soundCloudId} />
              </div>
            )}

            <section id="post-content">
              <PostContent content={post.content} />
            </section>

            {post.type === "VIDEO" && (
              <div>
                <Metadata post={post} />
                {post.movieSynopsis && (
                  <section>
                    <h2>Synopsis</h2>
                    {post.movieSynopsis}
                  </section>
                )}
                {post.soundCredits && (
                  <section>
                    <h2>Sound Credits</h2>
                    {processSoundCredits(post.soundCredits)}
                  </section>
                )}
              </div>
            )}
          </div>
        )
      }}
    </Query>
  )
}

const processSoundCredits = soundCredits => {
  const regex = /([a-z -.']+)(?:\s*\.{3,4}\s*)([a-z \-:().]+)\s*?/gi
  const credits = soundCredits
    .match(regex)
    .map(str => str.split(/(?:\s*\.{3,4}\s*)/))
  const renderRow = ([name, role], idx) => {
    return (
      <tr key={idx}>
        <td className="name">{name}</td>
        <td className="role">{role}</td>
      </tr>
    )
  }

  return (
    <table className="sound-credits" summary="List of Sound Credits">
      <tbody>{credits.map(renderRow)}</tbody>
    </table>
  )
}

const Metadata = ({ post }) => {
  if (
    !post.movieSynopsis &&
    !post.soundStudio &&
    !post.directedBy &&
    !post.originalMusicBy
  ) {
    return null
  }

  return (
    <div className="movie-metadata">
      {post.movieSynopsis && (
        <div>
          <h4>Movie Studio</h4>
          <p>{post.movieStudio}</p>
        </div>
      )}

      {post.soundStudio && (
        <div>
          <h4>Sound Studio</h4>
          <p>{post.soundStudio}</p>
        </div>
      )}

      {post.directedBy && (
        <div>
          <h4>Director</h4>
          <p>{post.directedBy}</p>
        </div>
      )}

      {post.originalMusicBy && (
        <div>
          <h4>Original Music</h4>
          <p>{post.originalMusicBy}</p>
        </div>
      )}
    </div>
  )
}

const GET_POST = gql`
  query GetPostQuery($slug: String!) {
    post: getPost(slug: $slug) {
      slug
      title
      excerpt
      content
      type
      featured
      publishedAt
      shortUrl
      largeImage: coverImage(version: LARGE) {
        url
      }
      mediumImage: coverImage(version: MEDIUM) {
        url
      }

      ... on News {
        source
        sourceUrl
      }

      ... on Video {
        directedBy
        html
        movieStudio
        movieSynopsis
        originalMusicBy
        soundCredits
        soundStudio
        thumbnailUrl
        vimeoUrl
      }

      ... on Podcast {
        soundCloudId
        soundCloudUrl
      }
    }
  }
`
export default withRouter(Show)
