import React from "react"
import gql from "graphql-tag"
import { Query } from "react-apollo"
import Latest from "../components/Latest"
import Post from "../components/Post"
import { Redirect } from "react-router-dom"
import { Button, Dropdown } from "react-bootstrap"

const Landing = ({ parent, slug }) => {
  const variables = {
    first: 13,
    parent,
    slug,
    postSlug: slug ? slug : "",
  }

  return (
    <Query query={CATEGORY_QUERY} variables={variables}>
      {({ loading, data, fetchMore }) => {
        if (loading) {
          return null
        }

        if (data.post) {
          return <Redirect to={{ pathname: `/post/${data.post.slug}` }} />
        }

        const category = data.category

        if (category === null) {
          return <h1>Not Found</h1>
        }

        const [currentCategory, categories] = filters(
          data.categories,
          parent,
          slug,
        )
        const pageInfo = category.posts.pageInfo
        const edges = category.posts.edges

        if (edges.length < 1) {
          return (
            <React.Fragment>
              <h1>No posts availble in this category</h1>
              <p>Please select a different category:</p>
              <CategoryDropdown
                currentCategory={currentCategory}
                categories={categories}
                parent={parent}
              />
            </React.Fragment>
          )
        }
        const [post, ...posts] = edges

        return (
          <div>
            <Latest post={post.node} />
            <hr />
            <h5
              style={{
                fontWeight: "bold",
                textAlign: "center",
                textTransform: "uppercase",
                margin: "40px 20px 20px",
              }}
            >
              {category.name}
            </h5>
            <div className="d-flex flex-row-reverse">
              <CategoryDropdown
                currentCategory={currentCategory}
                categories={categories}
                parent={parent}
              />
            </div>
            <div className="post-list">
              {posts.map(({ cursor, node }, idx) => (
                <Post key={cursor} post={node} />
              ))}
            </div>
            {pageInfo.hasNextPage && (
              <Button
                variant="light"
                className="btn-sm d-block ml-auto mr-auto mt-5 mb-5 border"
                onClick={e => {
                  e.preventDefault()
                  return fetchMore({
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                      const newEdges = fetchMoreResult.category.posts.edges
                      const pageInfo = fetchMoreResult.category.posts.pageInfo

                      return newEdges.length
                        ? {
                            category: {
                              __typename: previousResult.category.__typename,
                              name: previousResult.category.name,
                              posts: {
                                __typename:
                                  previousResult.category.posts.__typename,
                                edges: [
                                  ...previousResult.category.posts.edges,
                                  ...newEdges,
                                ],
                                pageInfo,
                              },
                            },
                          }
                        : previousResult
                    },
                    variables: {
                      cursor: pageInfo.endCursor,
                      first: 9,
                      parent,
                      slug,
                    },
                  })
                }}
              >
                Load More
              </Button>
            )}
          </div>
        )
      }}
    </Query>
  )
}

const CategoryDropdown = ({ categories, currentCategory, parent }) => (
  <Dropdown>
    <Dropdown.Toggle
      variant="light"
      size="sm"
      className="mb-4 border"
      id="dropdown-basic"
    >
      <strong>Category: </strong>
      {currentCategory.name}
    </Dropdown.Toggle>

    <Dropdown.Menu alignRight>
      {categories.map(c => {
        const href = c.slug ? `/${parent}/${c.slug}` : `/${parent}`
        return (
          <Dropdown.Item key={c.slug} href={href}>
            {c.name}
          </Dropdown.Item>
        )
      })}
    </Dropdown.Menu>
  </Dropdown>
)

const filters = (categories, parent, slug) => {
  categories = categories.filter(c => c.slug === parent)[0].children

  const recent = { slug: null, name: "Recent" }
  const current = slug ? categories.filter(c => c.slug === slug)[0] : recent

  categories = categories.filter(c => c.slug !== slug)

  if (!current) {
    return [recent].concat(categories)
  }

  if (current.name === "Recent") {
    return [current, categories]
  } else {
    return [current, [recent].concat(categories)]
  }
}

const CATEGORY_QUERY = gql`
  query LandingQuery(
    $parent: String!
    $slug: String
    $first: Int!
    $cursor: String
    $postSlug: String!
  ) {
    post: getPost(slug: $postSlug) {
      slug
    }
    categories: categoryTree {
      slug
      name
      children {
        slug
        name
      }
    }
    category: getCategory(parent: $parent, slug: $slug) {
      slug
      name
      posts(first: $first, after: $cursor) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            ...PostNode
          }
        }
      }
    }
  }
  ${Post.fragments.node}
`

export default Landing
