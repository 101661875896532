import React from "react"
import { withRouter } from "react-router-dom"
import { FormGroup, Col, Row } from "react-bootstrap"
import { Field, Form } from "react-final-form"
import fetch from "isomorphic-fetch"
import Select from "react-select"
import Checkbox from "../../../components/Checkbox"
import { POST_TYPES } from "../../../app/App"
import Input from "../../../components/Input"

const SharedFields = ({ form }) => (
  <React.Fragment>
    <Input name="title" label="Title" />
    <Input name="content" label="Content" as="textarea" rows={10} />
  </React.Fragment>
)

const VideoFields = ({ values, form }) => (
  <React.Fragment>
    <Input
      name="vimeoUrl"
      label="Video URL"
      onBlur={_ => fetchVideoData(values, form.change)}
    />
    <SharedFields form={form} />
    <Input name="movieSynopsis" label="Movie Synopsis" as="textarea" rows={5} />
    <Input name="soundCredits" label="Sound Credits" as="textarea" rows={5} />
    <Input name="movieStudio" label="Movie Studio" />
    <Input name="soundStudio" label="Sound Studio" />
    <Input name="directedBy" label="Directed By" />
    <Input name="originalMusicBy" label="Original Music By" />
    <Field name="html" type="hidden" component="input" />
    <Field name="thumbnailUrl" type="hidden" component="input" />
  </React.Fragment>
)

const NewsFields = ({ form }) => (
  <React.Fragment>
    <SharedFields form={form} />
    <Input name="source" label="Source" />
    <Input name="sourceUrl" label="Source URL" />
  </React.Fragment>
)

const PodcastFields = ({ values, form }) => (
  <React.Fragment>
    <Input
      name="soundCloudUrl"
      label="Soundcloud URL"
      onBlur={e => fetchSoundCloudData(values, form.change)}
    />
    <SharedFields form={form} />
    <Field name="soundCloudId" type="hidden" component="input" />
  </React.Fragment>
)

const PostForm = ({ history, onSubmit, post, children }) => {
  return (
    <Row>
      <Col md={8}>
        <Form onSubmit={onSubmit} initialValues={getInitialValues(post)}>
          {({
            submitError,
            handleSubmit,
            pristine,
            invalid,
            submitting,
            values,
            form,
          }) => (
            <form onSubmit={handleSubmit}>
              {submitError && (
                <div className="alert alert-danger">
                  <strong>Errors prevented this post from being saved.</strong>
                  <ul>
                    {submitError.map(({ key, message }) => (
                      <li>
                        <strong>{key}</strong> {message}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <Input name="type" label="Post Type">
                {({ input }) => {
                  const options = POST_TYPES.map(name => ({
                    label: name,
                    value: name.toUpperCase(),
                  }))
                  return (
                    <Select
                      {...input}
                      value={options.filter(
                        ({ value }) => value === values.type
                      )}
                      id="type"
                      placeholder="Please select a post type"
                      onChange={({ value }) =>
                        input.onChange(value ? value : null)
                      }
                      options={options}
                    />
                  )
                }}
              </Input>

              {values.type === "NEWS" && (
                <NewsFields values={values} form={form} />
              )}
              {values.type === "VIDEO" && (
                <VideoFields values={values} form={form} />
              )}
              {values.type === "PODCAST" && (
                <PodcastFields values={values} form={form} />
              )}

              {values.type && (
                <div>
                  <FormGroup name="featured">
                    <Field
                      label="Featured"
                      name="featured"
                      inline
                      type="checkbox"
                      component={Checkbox}
                    />
                  </FormGroup>
                </div>
              )}
              <input
                type="submit"
                value="Save"
                className="btn btn-primary"
                disabled={invalid || pristine || submitting}
              />
              {post && (
                <a href={`/post/${post.slug}`} className="btn btn-default">
                  View
                </a>
              )}
            </form>
          )}
        </Form>
      </Col>
      <Col md={4}>{children}</Col>
    </Row>
  )
}

const changeIfBlank = (values, change) => (key, value) => {
  if (!values[key] === "" || values[key] === undefined) {
    change(key, value)
  }
}

const fetchSoundCloudData = (values, change) => {
  const url = values.soundCloudUrl
  const update = changeIfBlank(values, change)

  fetch(`https://soundcloud.com/oembed?format=json&url=${url}&maxheight=81`)
    .then(result => result.json())
    .then(json => {
      update("title", json.title)
      update("content", json.description)
      change("soundCloudId", soundCloudId(json.html))
    })
    .catch(error => console.error(error))
}

const soundCloudId = html => {
  const result = html.match(/api.soundcloud.com%2F(tracks|playlists)%2F(\d+)/)
  return `${result[1]}/${result[2]}`
}

const fetchVideoData = (values, change) => {
  const url = String(values.vimeoUrl)

  switch (url) {
    case (url.match(/(you)/) || {}).input:
      fetchYouTubeData(values, change)
      break
    case (url.match(/vimeo/) || {}).input:
      fetchVimeoData(values, change)
      break
    default:
      console.log("Didn't match")
      break
  }
}

const fetchVimeoData = (values, change) => {
  const url = values.vimeoUrl
  const update = changeIfBlank(values, change)

  return fetch(`https://vimeo.com/api/oembed.json?width=1920&url=${url}`)
    .then(result => result.json())
    .then(json => {
      update("title", json.title)
      update("content", json.description)
      change("html", json.html)
      change("thumbnailUrl", json.thumbnail_url)
    })
    .catch(error => console.error(error))
}

const fetchYouTubeData = (values, change) => {
  const url = values.vimeoUrl
  const update = changeIfBlank(values, change)

  return fetch(`https://youtube.com/oembed?url=${url}`)
    .then(result => result.json())
    .then(json => {
      update("title", json.title)
      update("content", json.description)
      change("html", json.html)
      change("thumbnailUrl", json.thumbnail_url)
    })
    .catch(error => console.error(error))
}

const getInitialValues = post => {
  if (post && post.type === "NEWS") {
    return {
      content: post.content,
      featured: post.featured,
      source: post.source,
      sourceUrl: post.sourceUrl,
      title: post.title,
      type: post.type,
    }
  } else if (post && post.type === "PODCAST") {
    return {
      content: post.content,
      featured: post.featured,
      soundCloudId: post.soundCloudId,
      soundCloudUrl: post.soundCloudUrl,
      title: post.title,
      type: post.type,
    }
  } else if (post && post.type === "VIDEO") {
    return {
      content: post.content,
      directedBy: post.directedBy,
      featured: post.featured,
      html: post.html,
      movieStudio: post.movieStudio,
      movieSynopsis: post.movieSynopsis,
      originalMusicBy: post.originalMusicBy,
      soundCredits: post.soundCredits,
      soundStudio: post.soundStudio,
      source: post.source,
      sourceUrl: post.sourceUrl,
      thumbnailUrl: post.thumbnailUrl,
      title: post.title,
      type: post.type,
      vimeoUrl: post.vimeoUrl,
    }
  } else {
    return null
  }
}

export default withRouter(PostForm)
