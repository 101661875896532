import React from "react"
import gql from "graphql-tag"
import { graphql, compose } from "react-apollo"
import { LinkContainer } from "react-router-bootstrap"
import { Table, Button } from "react-bootstrap"
import PageHeader from "../../../components/PageHeader"

const CategoriesIndex = props => {
  const { loading, categories } = props.data

  if (loading) {
    return null
  }

  return (
    <div>
      <PageHeader>Categories</PageHeader>

      <LinkContainer to={`/admin/categories/new`}>
        <Button>New Category</Button>
      </LinkContainer>

      <Table bordered striped>
        <thead>
          <tr>
            <td>Name</td>
            <td>Parent</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>{categories.map(renderCategoryRow)}</tbody>
      </Table>
    </div>
  )
}

const renderCategoryRow = category => {
  return (
    <tr key={`${parentCategoryName(category)}-${category.slug}`}>
      <td>{category.name}</td>
      <td>{parentCategoryName(category)}</td>
      <td />
    </tr>
  )
}

const parentCategoryName = category => {
  if (category.parent && category.parent.name) {
    return category.parent.name
  }
  return "Root"
}

const categoriesQuery = gql`
  query {
    categories: allCategories {
      name
      slug
      parent {
        name
        slug
      }
    }
  }
`

export default compose(graphql(categoriesQuery))(CategoriesIndex)
