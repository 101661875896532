import React from "react"
import gql from "graphql-tag"
import { graphql } from "react-apollo"
import { Carousel, Image } from "react-bootstrap"

const Featured = props => {
  const { data, loading } = props

  if (loading) {
    return null
  }

  return (
    <Carousel style={{ marginBottom: "40px" }}>
      {data && data.featured && data.featured.edges.map(renderNode)}
    </Carousel>
  )
}

const renderNode = ({ node }) => {
  const { id, largeImage, slug, thumbnailUrl, title } = node

  return (
    <Carousel.Item key={id}>
      <a href={`/post/${slug}`}>
        <Image
          fluid
          alt={title}
          src={largeImage ? largeImage.url : thumbnailUrl}
        />
        <div className="carousel-caption d-none d-block">
          <h4>{title}</h4>
        </div>
      </a>
    </Carousel.Item>
  )
}

const FetchFeatured = gql`
  query Featured {
    featured: allPosts(first: 10, featured: true) {
      edges {
        node {
          id
          slug
          title
          largeImage: coverImage(version: LARGE) {
            url
          }
          ... on Video {
            thumbnailUrl
          }
        }
      }
    }
  }
`

export default graphql(FetchFeatured)(Featured)
