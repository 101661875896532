import React from "react"
import { ApolloProvider } from "react-apollo"
import { render } from "react-dom"
import App from "./app/App"
import { toast } from "react-toastify"
import client from "./app/client"
import registerServiceWorker from "./app/registerServiceWorker"
import "react-toastify/dist/ReactToastify.css"

render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root") as HTMLElement,
)

registerServiceWorker(toast)
