import React from "react"
import { Navbar, Nav } from "react-bootstrap"

import logo from "../img/logo.png"

const AdminHeader = () => (
  <Navbar bg="light" variant="light">
    <Navbar.Brand>
      <a href="/admin">
        <img src={logo} height={35.5} width={227} alt="SoundWorks Collection" />
      </a>
    </Navbar.Brand>
    <Nav>
      <Nav.Item>
        <Nav.Link href="/admin/categories">Categories</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/admin/posts">Posts</Nav.Link>
      </Nav.Item>
    </Nav>
    <Nav className="ml-auto">
      <Nav.Link href="/">
        <Nav.Item>&#171; Back to site</Nav.Item>
      </Nav.Link>
    </Nav>
  </Navbar>
)

export default AdminHeader
