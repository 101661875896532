import React, { useState } from "react"
import { Mutation } from "react-apollo"
import gql from "graphql-tag"
import { ApolloConsumer, Query } from "react-apollo"
import { withRouter } from "react-router-dom"
import { Image, ResponsiveEmbed } from "react-bootstrap"
import PostForm from "./Form"
import PageHeader from "../../../components/PageHeader"
import CategoryManager from "../../../components/CategoryManager"
import DeletePost from "../../../components/DeletePost"
import Publisher from "../../../components/Publisher"
import Soundcloud from "../../../components/SoundCloud"

const UpdateForm = ({ history, match, ...rest }) => {
  const slug = match.params.slug

  return (
    <Query query={GET_POST} variables={{ slug }}>
      {props => {
        if (props.loading) {
          return null
        }

        const { post, categories } = props.data

        return (
          <ApolloConsumer>
            {client => {
              const onSubmit = async values =>
                client
                  .mutate(updateMutationArg(values, slug))
                  .then(({ data }) => {
                    if (data.result && data.result.errors === null) {
                      history.push(`/admin/posts/${slug}/edit`)
                    }
                  })

              return (
                <React.Fragment>
                  <PageHeader>{post.title}</PageHeader>
                  <PostForm {...rest} post={post} onSubmit={onSubmit}>
                    <Sidebar
                      post={post}
                      categories={categories}
                      history={history}
                    />
                  </PostForm>
                </React.Fragment>
              )
            }}
          </ApolloConsumer>
        )
      }}
    </Query>
  )
}

const UPLOAD_IMAGE = gql`
  mutation UploadImage($slug: String!, $imageType: ImageType, $image: Upload!) {
    upload: uploadImage(slug: $slug, imageType: $imageType, image: $image) {
      url
      errors {
        key
        message
      }
    }
  }
`

const ImageUploader = ({ slug, imageType, setImage }) => (
  <Mutation mutation={UPLOAD_IMAGE}>
    {(uploadImage, { called, data }) => (
      <div>
        <input
          type="file"
          accept={"image/jpeg,image/png"}
          onChange={({ target }) => {
            if (target.validity.valid) {
              uploadImage({
                variables: { slug, imageType, image: target.files[0] },
              }).then(({ data: { upload } }) => {
                if (upload.url) {
                  setImage({ url: upload.url })
                }
              })
            }
          }}
        />
      </div>
    )}
  </Mutation>
)

const Sidebar = ({ post, categories, history }) => {
  const [image, setImage] = useState(post.smallImage)

  return (
    <React.Fragment>
      <h4>Image</h4>
      {image && image.url && <Image thumbnail fluid src={image.url} />}
      <ImageUploader imageType="COVER" slug={post.slug} setImage={setImage} />

      <hr />
      {post.type === "VIDEO" && (
        <React.Fragment>
          <h4>Video Embed</h4>
          <ResponsiveEmbed a16by9>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </ResponsiveEmbed>
          <hr />
        </React.Fragment>
      )}
      {post.type === "PODCAST" && (
        <React.Fragment>
          <h4>Soundcloud Embed</h4>
          <ResponsiveEmbed a16by9>
            <Soundcloud id={post.soundCloudId} />
          </ResponsiveEmbed>
          <hr />
        </React.Fragment>
      )}
      <React.Fragment>
        <h4>Manage Categories</h4>
        <CategoryManager post={post} categories={categories} />
        <hr />

        {post.publishedAt && (
          <React.Fragment>
            <h4>Short URL</h4>
            <p>
              <code>{post.shortUrl}</code>
            </p>
            <hr />
          </React.Fragment>
        )}

        <h4>Publish Options</h4>
        <Publisher post={post} />
        {!post.publishedAt && (
          <React.Fragment>
            <hr />
            <DeletePost history={history} slug={post.slug} />
            <hr />
          </React.Fragment>
        )}
      </React.Fragment>
    </React.Fragment>
  )
}

const updateMutationArg = (values, slug) => {
  switch (values.type) {
    case "NEWS":
      return {
        mutation: UPDATE_NEWS_POST,
        refetchQueries: ["GetPost", "Posts"],
        variables: {
          input: {
            content: values.content,
            featured: values.featured,
            source: values.source,
            sourceUrl: values.sourceUrl,
            title: values.title,
          },
          slug: slug,
        },
      }
    case "PODCAST":
      return {
        mutation: UPDATE_PODCAST_POST,
        refetchQueries: ["GetPost", "Posts"],
        variables: {
          input: {
            content: values.content,
            featured: values.featured,
            soundCloudId: values.soundCloudId,
            soundCloudUrl: values.soundCloudUrl,
            title: values.title,
          },
          slug: slug,
        },
      }
    case "VIDEO":
      return {
        mutation: UPDATE_VIDEO_POST,
        refetchQueries: ["GetPost", "Posts"],
        variables: {
          input: {
            content: values.content,
            directedBy: values.directedBy,
            featured: values.featured,
            html: values.html,
            movieStudio: values.movieStudio,
            movieSynopsis: values.movieSynopsis,
            originalMusicBy: values.originalMusicBy,
            soundCredits: values.soundCredits,
            soundStudio: values.soundStudio,
            source: values.source,
            sourceUrl: values.sourceUrl,
            thumbnailUrl: values.thumbnailUrl,
            title: values.title,
            vimeoUrl: values.vimeoUrl,
          },
          slug: slug,
        },
      }
    default:
      return
  }
}

const GET_POST = gql`
  query GetPost($slug: String!) {
    categories: categoryTree {
      label: name
      options: children {
        label: name
        value: id
      }
    }
    post: getPost(slug: $slug) {
      title
      slug
      content
      type
      featured
      publishedAt
      shortUrl
      categories {
        id
        name
        slug
      }
      smallImage: coverImage(version: SMALL) {
        url
      }

      ... on News {
        source
        sourceUrl
      }

      ... on Podcast {
        soundCloudId
        soundCloudUrl
      }

      ... on Video {
        directedBy
        html
        movieStudio
        movieSynopsis
        originalMusicBy
        soundCredits
        soundStudio
        thumbnailUrl
        vimeoUrl
      }
    }
  }
`

const UPDATE_NEWS_POST = gql`
  mutation UpdateNewsPost($slug: String!, $input: NewsPostInput!) {
    result: updateNewsPost(slug: $slug, input: $input) {
      post {
        id
        slug
      }
      errors {
        key
        message
      }
    }
  }
`

const UPDATE_PODCAST_POST = gql`
  mutation UpdatePodcastPost($slug: String!, $input: PodcastPostInput!) {
    result: updatePodcastPost(slug: $slug, input: $input) {
      post {
        id
        slug
      }
      errors {
        key
        message
      }
    }
  }
`

const UPDATE_VIDEO_POST = gql`
  mutation UpdateVideoPost($slug: String!, $input: VideoPostInput!) {
    result: updateVideoPost(slug: $slug, input: $input) {
      post {
        id
        slug
      }
      errors {
        key
        message
      }
    }
  }
`

export default withRouter(UpdateForm)
