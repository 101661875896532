import React from "react"
import gql from "graphql-tag"
import { graphql } from "react-apollo"
import Post from "./Post"

const Posts = props => {
  const { loading, posts } = props.data

  if (loading) {
    return null
  }

  return (
    <div className="post-list">
      {posts &&
        posts.edges.map(({ cursor, node }) => (
          <Post key={cursor} post={node} />
        ))}
    </div>
  )
}

const FetchPosts = gql`
  query Posts(
    $first: Int!
    $type: [PostType]
    $cursor: String
    $published: Boolean
    $featured: Boolean
  ) {
    posts: allPosts(
      first: $first
      after: $cursor
      type: $type
      published: $published
      featured: $featured
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          ...PostNode
        }
      }
    }
  }
  ${Post.fragments.node}
`

export default graphql(FetchPosts, {
  options: ({ first, type }) => ({
    variables: {
      first,
      type,
    },
  }),
})(Posts)
