import React from "react"
import gql from "graphql-tag"
import { Mutation } from "react-apollo"
import { withRouter } from "react-router-dom"
import { Container, Image } from "react-bootstrap"
import { Form } from "react-final-form"
import { FORM_ERROR } from "final-form"
import Input from "../../components/Input"
import PageHeader from "../../components/PageHeader"
import logo from "../../img/logo.png"

const required = value => (value ? undefined : "Required")

const styles = {
  backgroundColor: "#fff",
  padding: 20,
  maxWidth: 400,
  marginTop: 40,
  borderRadius: 4,
  boxShadow: "0 0 8px rgba(0,0,0,0.2)",
}

const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      expiresAt
      error
    }
  }
`

const Login = ({ history, location }) => (
  <Mutation mutation={LOGIN}>
    {(mutate, { called, data }) => {
      const onSubmit = async ({ username, password }) => {
        const { from } = location.state || { from: { pathname: "/" } }

        return mutate({ variables: { username, password } }).then(
          ({ data: { login } }) => {
            if (login && login.error) {
              localStorage.removeItem("expires")
              localStorage.removeItem("token")
              return { [FORM_ERROR]: login.error }
            } else {
              localStorage.setItem("expires", login.expiresAt)
              localStorage.setItem("token", login.token)
              history.push(from)
            }
          }
        )
      }

      return (
        <Container style={styles}>
          <Image src={logo} className="mt-5 mb-5" fluid />
          <PageHeader>Log in</PageHeader>
          <Form onSubmit={onSubmit} valideOnBlur={true}>
            {({ submitError, handleSubmit, pristine, submitting }) => (
              <React.Fragment>
                {submitError && (
                  <p className="alert alert-danger">{submitError}</p>
                )}
                <form onSubmit={handleSubmit}>
                  <Input name="username" label="Username" validate={required} />
                  <Input
                    name="password"
                    label="Password"
                    type="password"
                    validate={required}
                  />
                  <hr />
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-block"
                    disabled={pristine || submitting}
                  >
                    Log in
                  </button>
                </form>
              </React.Fragment>
            )}
          </Form>
        </Container>
      )
    }}
  </Mutation>
)

export default withRouter(Login)
