import React from "react"
import coleman from "../../img/Coleman.jpg"
import tucker from "../../img/tucker.jpg"
import vedder from "../../img/Vedder_New.jpg"
import hlutke from "../../img/Hltuke.jpg"
import burlingame from "../../img/Burlingame.jpg"
import noguchi from "../../img/Yuki.jpg"
import scroggin from "../../img/Scroggin.jpg"
import { Col, Image, Row } from "react-bootstrap"

const About = () => (
  <div id="about-page">
    <section>
      <h2>Our Mission</h2>
      <p>
        The goal for the SoundWorks Collection is simple; we are dedicated to
        profiling the greatest and upcoming sound minds from around the world
        and highlight their contributions. The SoundWorks Collection was created
        in 2009 by filmmaker&nbsp;
        <a href="http://www.colemanfilm.com">Michael Coleman</a> as an online
        destination that takes you behind the scenes and straight to the dub
        stage for a look into audio post-production for feature films, video
        game sound design, and original soundtrack composition.
      </p>
      <p>
        This exclusive and intimate video series focuses on individuals and
        teams behind-the-scenes bringing to life some of the worlds most
        exciting projects. The SoundWorks Collection video series has surpassed
        200 million hits and 8 million views and has included interviews with
        directors Steven Spielberg, George Lucas, James Cameron, Mel Gibson,
        Robert Zemeckis, Brad Bird, Mark Andrews, Lee Unkrich, Edgar Wright,
        Brian Helgeland, Joseph Kosinski, and many other leaders in the film
        industry.
      </p>
    </section>

    <hr />

    <section className="flex">
      <section>
        <h2>Film Sound Profile</h2>
        <p>
          Who will bring home the sound Oscar this year? The SoundWorks
          Collection focuses on Hollywood’s feature film releases throughout the
          year. Watch how the sound crew work their audio magic as they put the
          finishing touches on your favorite film.
        </p>
      </section>
      <section>
        <h2>Game Sound Profile</h2>
        <p>
          Game soundtracks are no longer about the beeps and bloops of our
          childhood past. Explore your favorite game titles sound design from
          the world’s most innovative sound teams. Playing video games isn’t
          just about what you see on the screen, it’s about what you hear around
          you...and in the surrounds.
        </p>
      </section>
      <section>
        <h2>Music Composers</h2>
        <p>
          Ever wonder who is responsible for making you tear up uncontrollably
          during your favorite movie scene? It’s likely the brilliant mind of
          the composer who created that memorable score. Explore the process
          into today’s leading composers and see what they hear inside their
          head.
        </p>
      </section>
      <section>
        <h2>Studio Tour</h2>
        <p>
          Ever wonder what it is like to walk into a world class studio or
          soundstage? Now you can see the technology and gear that the pros use
          every day to create their tracks. Get an exclusive tour into some of
          your favorite studios and see what toys the top dogs are using.
        </p>
      </section>
    </section>

    <hr />

    <section>
      <h2>Partnerships &amp; Feedback</h2>
      <p>
        If you are interested in advertising, sponsorship, and licensing
        opportunities, or other business development ventures please send an
        email to us at&nbsp;
        <a href="mailto:info@soundworkscollection.com">
          info@soundworkscollection.com
        </a>
      </p>
    </section>

    <hr />

    <section className="team">
      <h1 className="text-center mb-5">Our Team</h1>
      <Row>
        <Member
          name="Michael Coleman"
          image={coleman}
          position="Founder & Film Director"
          location="Novato, CA"
        />
        <Member
          name="Michael Tucker"
          image={tucker}
          position="Camera Operator"
          location="Los Angeles, CA"
        />
        <Member
          name="Brandon Vedder"
          image={vedder}
          position="Camera Operator"
          location="Auburn, CA"
        />
      </Row>
      <Row>
        <Member
          name="Jonathan Malcolm Hltuke"
          image={hlutke}
          position="Camera Operator"
          location="Los Angeles, CA"
        />
        <Member
          name="Jon Burlingame"
          image={burlingame}
          position="Writer"
          location="Los Angeles, CA"
        />
        <Member
          name="Yuki Noguchi"
          image={noguchi}
          position="Camera Operator"
          location="Los Angeles, CA"
        />
      </Row>
      <Row>
        <Member
          name="Sonny Scroggin"
          image={scroggin}
          position="Software Engineer"
          location="Nashville, TN"
        />
      </Row>
    </section>
  </div>
)

const Member = ({ name, image, position, location }) => (
  <Col className="mb-5 text-center">
    <Image
      src={image}
      alt={name}
      flex
      width={200}
      height={200}
      className="rounded-circle mb-3"
    />
    <h5>{name}</h5>
    <p className="mb-0">{position}</p>
    <span className="small">{location}</span>
  </Col>
)

export default About
