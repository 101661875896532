import {ApolloClient} from "apollo-client"
import {ApolloLink} from "apollo-link"
import {setContext} from "apollo-link-context"
import {InMemoryCache, IntrospectionFragmentMatcher} from "apollo-cache-inmemory"
import {createLink} from "apollo-absinthe-upload-link"
import moment from "moment"

const isExpired = (unix) => {
  const int = parseInt(unix, 10)

  if (isNaN(int)) {
    return false
  } else {
    const exp = moment.unix(int).utc()
    return exp.diff(moment().utc()) < 0
  }
}

const httpLink = createLink({uri: process.env.REACT_APP_GRAPHQL_URL})

const authLink = setContext((_, {headers}) => {
  const token = localStorage.getItem("token")
  const exp = localStorage.getItem("expires")

  if (token && exp) {
    if (isExpired(exp)) {
      localStorage.removeItem("token")
      localStorage.removeItem("expires")
    }
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    }
  }
})

const link = ApolloLink.from([
  authLink,
  httpLink,
])

const cache = new InMemoryCache({
  fragmentMatcher: new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: [
          {
            kind: "INTERFACE",
            name: "Post",
            possibleTypes: [
              { name: "News" },
              { name: "Podcast" },
              { name: "Video" },
            ],
          },
        ],
      },
    }
  })
})

export default new ApolloClient({
  cache,
  link,
})
