import React from "react"

export default ({ id, height }) => {
  const url = `https://api.soundcloud.com/${id}`
  const opts = `color=%23ff5500&amp;auto_play=false&amp;hide_related=true&amp;show_comments=false&amp;show_user=false&amp;show_reposts=false&amp;show_teaser=false`

  return (
    <iframe
      title="soundcloud player"
      width="100%"
      height={height || defaultHeight(id)}
      scrolling="yes"
      frameBorder="no"
      allow="autoplay"
      src={`https://w.soundcloud.com/player/?url=${url}&amp;${opts}`}
    />
  )
}

const defaultHeight = id => (id.includes("playlists") ? 500 : 166)
