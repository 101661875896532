import React from "react"
import gql from "graphql-tag"
import { Query } from "react-apollo"
import PostQuery from "../components/Post"
import PostContent from "../components/PostContent"
import { Media } from "react-bootstrap"

const Post = ({ post }) => (
  <Media className="mb-2" style={{ maxHeight: 100, overflow: "hidden" }}>
    <a href={`/post/${post.slug}`}>
      <img
        className="mr-3"
        src={post.smallImage ? post.smallImage.url : post.thumbnailUrl}
        alt={post.title}
        height={120}
        width={200}
      />
    </a>
    <Media.Body>
      <a href={`/post/${post.slug}`}>
        <p
          style={{
            color: "#000",
            fontFamily: "Lora",
            fontSize: 16,
            lineHeight: 1.2,
            marginBottom: 12,
            fontWeight: "bold",
          }}
        >
          {post.title}
        </p>
      </a>
      <PostContent content={post.excerpt} lines={3} excerpt />
    </Media.Body>
  </Media>
)

const RecentPosts = ({ title, posts }) => (
  <div className="recent-posts">
    <h5
      style={{
        fontWeight: "bold",
        borderBottom: "1px solid #999",
        paddingBottom: 5,
        marginBottom: 10,
      }}
    >
      {title}
    </h5>
    <div className="posts">
      {posts.map(post => (
        <Post key={post.slug} post={post} />
      ))}
    </div>
  </div>
)

export default ({ title, type, first, after }) => (
  <Query query={QUERY} variables={{ type, first, after }}>
    {({ loading, data }) => {
      if (loading) {
        return null
      }
      return (
        <RecentPosts
          title={title}
          posts={data.posts.edges.map(({ node }) => node)}
        />
      )
    }}
  </Query>
)

const QUERY = gql`
  query Posts($first: Int!, $type: [PostType], $after: String) {
    posts: allPosts(first: $first, after: $after, type: $type) {
      edges {
        node {
          ...PostNode
        }
      }
    }
  }
  ${PostQuery.fragments.node}
`
