import React from "react"
import gql from "graphql-tag"
import { Mutation } from "react-apollo"
import { FormControl } from "react-bootstrap"
import { Field, Form } from "react-final-form"
import DatePicker from "react-datepicker"
import moment from "moment"
import "react-datepicker/dist/react-datepicker.css"

const Publisher = ({ post }) => (
  <Mutation mutation={PUBLISH_POST}>
    {(mutate, { called, data }) => (
      <Form
        onSubmit={values =>
          mutate({
            refetchQueries: ["GetPost"],
            variables: {
              input: { slug: post.slug, publishAt: values.publishAt },
            },
          })
        }
        initialValues={{
          publishAt: post.publishedAt ? moment(post.publishedAt) : null,
        }}
      >
        {({ handleSubmit, pristine, submitting, values, form }) => (
          <form onSubmit={handleSubmit}>
            <Field name="publishAt" type="hidden" component="input" />
            <DatePicker
              customInput={<FormControl />}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              selected={values.publishAt}
              onChange={value => form.change("publishAt", moment(value))}
              dateFormat="LLL"
            />
            <br />
            <br />
            <input
              type="submit"
              value="Publish"
              className="btn btn-outline-primary btn-block"
              disabled={pristine || submitting}
            />
          </form>
        )}
      </Form>
    )}
  </Mutation>
)

const PUBLISH_POST = gql`
  mutation PublishPost($input: PublishPostInput!) {
    result: publishPost(input: $input) {
      success
      errors
    }
  }
`

export default Publisher
