import React from "react"
import Form from "react-bootstrap/lib/Form"
import { Field } from "react-final-form"

export default ({ name, label, children, validate, ...props }) => (
  <Field name={name} validate={validate}>
    {({ input, meta }) => {
      const { error, touched } = meta

      return (
        <Form.Group controlId={name}>
          <Form.Label>{label}</Form.Label>
          {renderChildren(children, { input, meta, ...props })}
          {touched && error && <span className="help-block">{error}</span>}
        </Form.Group>
      )
    }}
  </Field>
)

const renderChildren = (children, { input, meta, ...props }) => {
  if (!children) {
    return <Form.Control {...input} {...props} />
  } else {
    return children({ input, meta, ...props })
  }
}
