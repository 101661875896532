import React from "react"
import { Container } from "react-bootstrap"
import { Route, Switch } from "react-router-dom"
import AdminHeader from "../components/AdminHeader"
import AdminPostIndex from "../pages/admin/posts/Index"
import CreatePost from "../pages/admin/posts/CreatePost"
import UpdatePost from "../pages/admin/posts/UpdatePost"
import AdminCategoriesIndex from "../pages/admin/categories/Index"
import AdminCategoryForm from "../pages/admin/categories/Form"

const AdminLayout = () => (
  <div className="admin-layout">
    <AdminHeader />
    <Container fluid>
      <Switch>
        <Route path="/admin/posts/:slug/edit" component={UpdatePost} />
        <Route path="/admin/posts/new" exact component={CreatePost} />
        <Route path="/admin/posts" component={AdminPostIndex} />
        <Route path="/admin/categories/new" component={AdminCategoryForm} />
        <Route path="/admin/categories" component={AdminCategoriesIndex} />
      </Switch>
    </Container>
  </div>
)

export default AdminLayout
