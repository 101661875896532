import React from "react"
import { Route, Switch } from "react-router-dom"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Container } from "react-bootstrap"
import PostShow from "../pages/posts/Show"
import Index from "../pages/Index"
import About from "../pages/about"
import Search from "../pages/search"
// @ts-ignore
import { DFPSlotsProvider, AdSlot } from "react-dfp"
import Landing from "../pages/Landing"
import { Default } from "../components/Responsive"
import { ToastContainer } from "react-toastify"

const AppLayout = () => {
  return (
    // @ts-ignore
    <DFPSlotsProvider
      dfpNetworkId="4982068"
      sizeMapping={[
        { viewport: [1024, 768], sizes: [[728, 90], [300, 250]] },
        { viewport: [900, 768], sizes: [[300, 250]] },
      ]}
      collapseEmptyDivs={true}
    >
      <ToastContainer />
      <Header />
      <Default>
        <div className="ad-units">
          <AdSlot sizes={[[728, 90]]} adUnit="SWCLeaderboard" />
        </div>
      </Default>
      <Container id="content-bg" fluid>
        <Switch>
          <Route path="/post/:slug">
            <PostShow />
          </Route>
          <Route path="/(videos|news|podcast)/:slug">
            {({ match }) => {
              const parent = match.params[0]
              const slug = match.params.slug

              return <Landing parent={parent} slug={slug} />
            }}
          </Route>
          <Route path="/news">
            <Landing parent="news" />
          </Route>
          <Route path="/videos">
            <Landing parent="videos" />
          </Route>
          <Route path="/podcast">
            <Landing parent="podcast" />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/search">
            <Search />
          </Route>
          <Route path="/">
            <Index />
          </Route>
        </Switch>
        <hr />
        <Footer />
      </Container>
      <Default>
        <div className="ad-units mb-3">
          <AdSlot sizes={[[728, 90]]} adUnit="SWCFooter" />
        </div>
      </Default>
    </DFPSlotsProvider>
  )
}

export default AppLayout
