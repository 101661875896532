import React from "react"
import gql from "graphql-tag"
import PostContent from "./PostContent"
import PublishedAt from "./PublishedAt"
import { Image } from "react-bootstrap"
import { Link } from "react-router-dom"

const styles = {
  publishedAt: {
    fontSize: "11px",
    fontWeight: 400,
    display: "block",
    marginBottom: 5,
  },
}

const Post = ({ post }) => {
  return (
    <div className="post-card">
      <div className="post-content">
        <Link to={`/post/${post.slug}`}>
          <Image
            src={(post.smallImage && post.smallImage.url) || post.thumbnailUrl}
            alt={post.title}
            fluid
          />
        </Link>
        <h3>
          <Link to={`/post/${post.slug}`}>{post.title}</Link>
        </h3>
        <PublishedAt
          style={styles.publishedAt}
          publishedAt={post.publishedAt}
        />
        <PostContent content={post.excerpt} excerpt />
      </div>
    </div>
  )
}

Post.fragments = {
  node: gql`
    fragment PostNode on Post {
      id
      slug
      title
      largeImage: coverImage(version: LARGE) {
        url
      }
      mediumImage: coverImage(version: MEDIUM) {
        url
      }
      smallImage: coverImage(version: SMALL) {
        url
      }
      tinyImage: coverImage(version: TINY) {
        url
      }
      excerpt
      publishedAt
      ... on Video {
        thumbnailUrl
      }
    }
  `,
}

export default Post
