import React from "react"
import Featured from "../components/Featured"
import Posts from "../components/Posts"
import Recent from "../components/RecentPosts"
import { Mobile, Default } from "../components/Responsive"
import { AdSlot } from "react-dfp"
import { Row, Col } from "react-bootstrap"

const Index = () => {
  return (
    <div>
      <Featured />

      <div className="ad-units bordered">
        <Mobile>
          <AdSlot
            sizes={[[300, 250]]}
            adUnit="SWCSidebarBoombox"
            shouldRefresh={() => true}
          />
        </Mobile>
        <Default>
          <AdSlot
            sizes={[[300, 250]]}
            adUnit="SWCSidebarBoombox"
            shouldRefresh={() => true}
          />
          <AdSlot
            sizes={[[300, 250]]}
            adUnit="SWCSidebarBoombox2"
            shouldRefresh={() => true}
          />
          <AdSlot
            sizes={[[300, 250]]}
            adUnit="SWCSidebarBoombox3"
            shouldRefresh={() => true}
          />
        </Default>
      </div>
      <h5
        style={{
          fontWeight: "bold",
          textAlign: "center",
          textTransform: "uppercase",
          margin: "40px",
        }}
      >
        Recent Videos
      </h5>
      <Posts type="VIDEO" first={9} />
      <Mobile>
        <div className="ad-units">
          <AdSlot
            sizes={[[300, 250]]}
            adUnit="SWCSidebarBoombox2"
            shouldRefresh={() => true}
          />
        </div>
      </Mobile>
      <Row className="mt-5">
        <Col md={6}>
          <Recent first={5} type="PODCAST" title="Recent Podcasts" />
        </Col>
        <Col md={6}>
          <Recent first={5} type="NEWS" title="Recent News" />
        </Col>
      </Row>
    </div>
  )
}

export default Index
