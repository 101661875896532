import React from "react"
import gql from "graphql-tag"
import PublishedAt from "./PublishedAt"
import { Image } from "react-bootstrap"
import { Link } from "react-router-dom"

const Latest = ({ post }) => {
  return (
    <div className="latest-post">
      <div className="post-content">
        <Link to={`/post/${post.slug}`}>
          <Image
            src={(post.largeImage && post.largeImage.url) || post.thumbnailUrl}
            alt={post.title}
            fluid
          />
        </Link>
        <PublishedAt publishedAt={post.publishedAt} />
        <h3>
          <Link style={{ fontWeight: "bold" }} to={`/post/${post.slug}`}>
            {post.title}
          </Link>
        </h3>
        <p className="excerpt">{post.excerpt}</p>
      </div>
    </div>
  )
}

Latest.fragments = {
  node: gql`
    fragment PostNode on Post {
      id
      slug
      title
      image
      excerpt
      publishedAt
      ... on Video {
        thumbnailUrl
      }
    }
  `,
}

export default Latest
