import React from "react"
import gql from "graphql-tag"
import { ApolloConsumer } from "react-apollo"
import { withRouter } from "react-router-dom"
import PostForm from "./Form"
import PageHeader from "../../../components/PageHeader"

const CreateForm = ({ history, ...props }) => (
  <ApolloConsumer>
    {client => {
      const onSubmit = async values =>
        client
          .mutate(createMutationArg(values))
          .then(({ data: { result } }) => {
            if (result && result.post) {
              history.push(`/admin/posts/${result.post.slug}/edit`)
            }
          })

      return (
        <React.Fragment>
          <PageHeader>New Post</PageHeader>
          <PostForm {...props} onSubmit={onSubmit} />
        </React.Fragment>
      )
    }}
  </ApolloConsumer>
)

const createMutationArg = values => {
  switch (values.type) {
    case "NEWS":
      return {
        mutation: CREATE_NEWS_POST,
        refetchQueries: ["Posts"],
        variables: {
          input: {
            content: values.content,
            featured: values.featured,
            source: values.source,
            sourceUrl: values.sourceUrl,
            title: values.title,
          },
        },
      }
    case "PODCAST":
      return {
        mutation: CREATE_PODCAST_POST,
        refetchQueries: ["Posts"],
        variables: {
          input: {
            content: values.content,
            featured: values.featured,
            soundCloudId: values.soundCloudId,
            soundCloudUrl: values.soundCloudUrl,
            title: values.title,
          },
        },
      }
    case "VIDEO":
      return {
        mutation: CREATE_VIDEO_POST,
        refetchQueries: ["Posts"],
        variables: {
          input: {
            content: values.content,
            directedBy: values.directedBy,
            featured: values.featured,
            html: values.html,
            movieStudio: values.movieStudio,
            movieSynopsis: values.movieSynopsis,
            originalMusicBy: values.originalMusicBy,
            soundCredits: values.soundCredits,
            soundStudio: values.soundStudio,
            source: values.source,
            sourceUrl: values.sourceUrl,
            thumbnailUrl: values.thumbnailUrl,
            title: values.title,
            vimeoUrl: values.vimeoUrl,
          },
        },
      }
    default:
      return
  }
}

const CREATE_PODCAST_POST = gql`
  mutation CreatePodcastPost($input: PodcastPostInput!) {
    result: createPodcastPost(input: $input) {
      post {
        id
        slug
      }
      errors {
        key
        message
      }
    }
  }
`

const CREATE_NEWS_POST = gql`
  mutation CreateNewsPost($input: NewsPostInput!) {
    result: createNewsPost(input: $input) {
      post {
        id
        slug
      }
      errors {
        key
        message
      }
    }
  }
`

const CREATE_VIDEO_POST = gql`
  mutation CreateVideoPost($input: VideoPostInput!) {
    result: createVideoPost(input: $input) {
      post {
        id
        slug
      }
      errors {
        key
        message
      }
    }
  }
`

export default withRouter(CreateForm)
