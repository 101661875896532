import React from "react"
import Markdown from "markdown-to-jsx"
import ClampLines from "react-clamp-lines"

export default ({ content, lines, excerpt, ...props }) => {
  if (excerpt) {
    return (
      <ClampLines
        {...props}
        className="excerpt d-inline-block"
        lines={lines || 4}
        text={content}
        buttons={false}
      />
    )
  }

  return <Markdown {...props} children={content} />
}
