import React from "react"
import gql from "graphql-tag"
import { Mutation, Query } from "react-apollo"
import { withRouter } from "react-router-dom"
import Select from "react-select"
import { Container, Row, Col } from "react-bootstrap"
import { Form } from "react-final-form"
import Input from "../../../components/Input"
import PageHeader from "../../../components/PageHeader"

const Page = ({ history, title }) => (
  <Container>
    <Row>
      <PageHeader>{title || "Create a new category"}</PageHeader>
      <Col lg={8}>
        <Query query={ROOT_CATEGORIES}>
          {({ data: { categories }, loading }) => {
            if (loading) {
              return null
            }

            return (
              <Mutation mutation={CREATE_CATEGORY}>
                {(mutate, { data, called }) => {
                  if (called && data && data.createCategory) {
                    if (!data.createCategory.errors) {
                      history.push(`/admin/categories`)
                    }

                    if (data.createCategory.errors) {
                      alert(data.createCategory.errors)
                    }
                  }

                  return (
                    <CategoryForm
                      onSubmit={({ name, parentId }) =>
                        mutate({ variables: { input: { name, parentId } } })
                      }
                      categories={categories}
                    />
                  )
                }}
              </Mutation>
            )
          }}
        </Query>
      </Col>
      <Col lg={4} />
    </Row>
  </Container>
)

export default withRouter(Page)

const required = value => (value ? undefined : "Required")

const CategoryForm = ({ onSubmit, categories }) => (
  <Form onSubmit={onSubmit} validateOnBlur={true}>
    {({ handleSubmit, pristine, invalid, submitting, values, errors }) => (
      <form onSubmit={handleSubmit}>
        <Input
          name="name"
          label="Name"
          placeholder="Name"
          validate={required}
        />

        <Input name="parentId" label="Parent Category">
          {({ input }) => (
            <Select
              {...input}
              id="parentId"
              placeholder="Please select a parent category"
              onChange={({ value }) => input.onChange(value ? value : null)}
              options={categories.map(({ name, id }) => ({
                label: name,
                value: id,
              }))}
            />
          )}
        </Input>

        <input
          type="submit"
          value="Submit"
          className="btn btn-primary"
          disabled={submitting || invalid || pristine}
        />
        <pre>{JSON.stringify(values, 0, 2)}</pre>
      </form>
    )}
  </Form>
)

const ROOT_CATEGORIES = gql`
  query {
    categories: categoryTree {
      id
      name
    }
  }
`

const CREATE_CATEGORY = gql`
  mutation CreateCategory($input: CategoryInput!) {
    createCategory(input: $input) {
      category {
        name
        slug
        parent {
          id
          name
          slug
        }
      }
      errors {
        key
        message
      }
    }
  }
`
