import React from "react"
import {
  FacebookShareButton,
  //FacebookIcon,
  TwitterShareButton,
  //TwitterIcon,
} from "react-share"
import { ReactComponent as Facebook } from "../img/facebook.svg"
import { ReactComponent as Twitter } from "../img/twitter.svg"

export default ({ post }) => {
  const shareUrl = post.shortUrl

  return (
    <React.Fragment>
      <FacebookShareButton quote={post.title} url={shareUrl}>
        <MediaOutlet>
          <Facebook fill="#3B5998" />
          Share
        </MediaOutlet>
      </FacebookShareButton>
      <TwitterShareButton title={post.title} url={shareUrl}>
        <MediaOutlet>
          <Twitter fill="#1DA1F2" />
          Tweet
        </MediaOutlet>
      </TwitterShareButton>
    </React.Fragment>
  )
}

export const MediaOutlet = ({ children }) => (
  <div className="media-outlet">{children}</div>
)

export const MediaOutletLink = ({ href, children }) => (
  <a className="media-outlet-link" href={href}>
    {children}
  </a>
)
