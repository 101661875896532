import React, { useEffect, useState } from "react"
import gql from "graphql-tag"
import { Query } from "react-apollo"
import { withRouter } from "react-router-dom"
import qs from "query-string"
import Post from "../../components/Post"
import { Form } from "react-final-form"
import Input from "../../components/Input"
import { Button } from "react-bootstrap"

const Search = ({ history }) => {
  const [search, setSearch] = useState(null)

  useEffect(
    () => {
      const query = qs.parse(history.location.search)
      if (query.q) {
        setSearch(query.q)
      }
    },
    [search],
  )

  return (
    <Query query={SEARCH_QUERY} variables={{ search }}>
      {({ loading, data, fetchMore }) => {
        const onSubmit = ({ q }) => {
          history.push(`/search?q=${q}`)
          setSearch(q)
        }

        return (
          <React.Fragment>
            <h1 className="mb-2">Search</h1>
            <Form onSubmit={onSubmit} initialValues={{ q: search }}>
              {({ handleSubmit, values, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <Input name="q" placeholder="What can we help you find?" />
                </form>
              )}
            </Form>
            {search && (
              <React.Fragment>
                <p>
                  Showing results for <strong>{search}</strong>
                </p>
                {!loading && data.posts && (
                  <React.Fragment>
                    <div className="post-list">
                      {data.posts.edges.map(({ cursor, node }, idx) => (
                        <Post key={cursor} post={node} />
                      ))}
                    </div>
                    {data.posts.pageInfo.hasNextPage && (
                      <Button
                        variant="light"
                        className="btn-sm d-block ml-auto mr-auto mt-5 mb-5 border"
                        onClick={e => {
                          e.preventDefault()
                          return fetchMore({
                            updateQuery: (
                              previousResult,
                              { fetchMoreResult },
                            ) => {
                              const newEdges = fetchMoreResult.posts.edges
                              const pageInfo = fetchMoreResult.posts.pageInfo

                              return newEdges.length
                                ? {
                                    posts: {
                                      __typename:
                                        previousResult.posts.__typename,
                                      edges: [
                                        ...previousResult.posts.edges,
                                        ...newEdges,
                                      ],
                                      pageInfo,
                                    },
                                  }
                                : previousResult
                            },
                            variables: {
                              cursor: data.posts.pageInfo.endCursor,
                              first: 9,
                              search,
                            },
                          })
                        }}
                      >
                        Load More
                      </Button>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )
      }}
    </Query>
  )
}

const SEARCH_QUERY = gql`
  query Posts($cursor: String, $search: String) {
    posts: allPosts(first: 9, after: $cursor, search: $search) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          title
          type
          slug
          publishedAt
          smallImage: coverImage(version: SMALL) {
            url
          }
        }
      }
    }
  }
`

export default withRouter(Search)
